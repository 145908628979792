export default class CardModel {
    registry_id: number;
    record_id: number;
    card: any;
    context: any;
    all_fields: any;
    values: any;
    fields_data: any;
    constructor(context: any) {
        this.card = context.getCard();
        this.context = context;
        this.registry_id = this.card.registryId
        this.record_id = this.card.recordId
    }

    async setFieldsProperties() {
        let values_data = await this.context.$http.get(
            `${this.context.$config.api}/registryservice/registry/${this.registry_id}/card/${this.record_id}`
        );
        this.values = values_data.data;
        let fields_data = await this.context.$http.get(
            `${this.context.$config.api}/objecteditor/entities?object_id=${this.registry_id}&is_field=true`
        );
        this.fields_data = fields_data.data.data;
    }
}
